@import 'style/colors';
@import 'style/sizes';

.button {
  padding: 4px 10px;
  font-size: 12px;
  color: #fff;
  background-color: #172a406d;
  border: none;

  &:focus,
  &:active {
    outline: none;
  }
}

.icon {
  margin-left: 2px;
}

.panelContainer {
  width: $blade-width-x-large;
  height: 100%;
  overflow-y: auto;
}

.featuresContainer {
  padding: 0 $spacer-xxs $spacer-lg;
}

.badgeCounter {
  position: absolute;
  right: 0.25rem;
  margin-top: 0.4rem;
  transform: scale(0.7);
  transform-origin: bottom right;
}

.tab {
  flex: 1 1 auto;
  padding: 10px 0;
  font-size: 13px;
  color: $secondary-100;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  list-style: none;
  border-bottom: 2px solid;

  &:focus {
    outline: none;
  }
}

.tabList {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px 0;
  margin-bottom: 5px;
  list-style: none;
}

.issueList {
  margin: 0 10px;
}

.right {
  z-index: 9;
  display: flex;
  height: 100%;
}

.search {
  padding: 9px 4px 9px 40px;

  // MdSearch icon
  background: transparent
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMThweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMThweCIgZmlsbD0iI0ZGRkZGRiI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xNS41IDE0aC0uNzlsLS4yOC0uMjdDMTUuNDEgMTIuNTkgMTYgMTEuMTEgMTYgOS41IDE2IDUuOTEgMTMuMDkgMyA5LjUgM1MzIDUuOTEgMyA5LjUgNS45MSAxNiA5LjUgMTZjMS42MSAwIDMuMDktLjU5IDQuMjMtMS41N2wuMjcuMjh2Ljc5bDUgNC45OUwyMC40OSAxOWwtNC45OS01em0tNiAwQzcuMDEgMTQgNSAxMS45OSA1IDkuNVM3LjAxIDUgOS41IDUgMTQgNy4wMSAxNCA5LjUgMTEuOTkgMTQgOS41IDE0eiIvPjwvc3ZnPg==')
    no-repeat 13px center;

  &::-webkit-search-cancel-button {
    appearance: none;
  }
}
