@import 'style/sizes';
@import 'style/colors';

$bottom-fader-height: 39px;

.bladeContentWrapper {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;

  // Just an element with the gray background for design purpose
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 84px;
    content: '';
    background: transparent linear-gradient(180deg, #fcfcfc 0%, $white 100%) 0%
      0% no-repeat padding-box;
  }

  // Bottom fader which is always exists
  &::after {
    position: absolute;

    // To fix intersection with scroll
    right: $left-panel-content-padding;
    bottom: 0;
    left: 0;
    z-index: var(
      --blade-content-z-index
    ); /* Could use the same z-index as the content */

    height: $bottom-fader-height;
    pointer-events: none; /* This allows users to interact with the content beneath */
    content: '';
    background: transparent linear-gradient(180deg, $white 0%, #fff0 100%) 0% 0%
      no-repeat padding-box;
    transform: matrix(-1, 0, 0, -1, 0, 0);
  }
}

.topFader {
  position: absolute;
  top: 0;

  // To fix intersection with scroll
  right: $left-panel-content-padding;
  left: 0;
  z-index: var(--blade-fader-z-index);
  height: 33px;
  pointer-events: none; /* This allows users to interact with the content beneath */
  background: transparent linear-gradient(180deg, #fcfcfc 0%, #fff0 100%) 0% 0%
    no-repeat padding-box;
}

.bladeContent {
  position: relative;
  z-index: var(--blade-content-z-index);
  flex-grow: 1;
  padding: $left-panel-content-padding $left-panel-content-padding
    $bottom-fader-height $left-panel-content-padding;
  overflow: hidden auto;
}

:root {
  --blade-content-z-index: 1;
  --blade-fader-z-index: calc(var(--blade-content-z-index) + 1);
  --blade-linear-loader-z-index: calc(var(--blade-fader-z-index) + 1);
}
