@use 'style/colors';
@use 'style/sizes';

@mixin item-padding($additional-padding: 16px) {
  padding: 8px 12px;

  &:first-child {
    padding-top: $additional-padding;
  }

  &:last-child {
    padding-bottom: $additional-padding;
  }
}

.item {
  @include item-padding;

  font-size: sizes.$font-size-default;
  cursor: pointer;
  list-style: none;
}

.optionsList {
  padding: 0;
  margin: 0;
}

.groupTitle {
  @include item-padding;

  font-size: sizes.$font-size-sm;
  font-weight: sizes.$semi-bold;
  color: colors.$secondary-900;
  list-style: none;
}

.divider {
  width: 100%;
  height: 1px;
  margin: 4px 0;
  background-color: colors.$secondary-100;
}
