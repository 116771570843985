@import 'style/colors';
@import 'style/sizes';

.message {
  margin-top: 60px;
  font-size: 12px;
}

.label {
  color: #fff;
}

.msgLarge {
  font-size: 25px;
  color: #cbcbcb;
  text-transform: uppercase;
}

.msgSmall {
  font-size: 20px;
  color: #ffffff73;
}

.cancelButton {
  background: transparent;
  border: none;
}

.icon {
  color: #fff;
}

.highlight {
  font-size: 21px;
  color: $primary;
}

.datasetsList {
  width: 500px !important;
  margin: auto;
  color: #fff !important;
  size: 14px;

  th,
  td {
    vertical-align: middle !important;
    text-align: left;
  }
}

@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 60px 0;
  }
}

@keyframes auto-progress {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.progressBar {
  width: 100%;
  height: 15px;
  background-color: #08101dbf;
  border-radius: 5px;
  box-shadow: 0 1px 5px #000 inset, 0 1px 0 #444;
}

.stripes {
  background-image: linear-gradient(
    135deg,
    rgb(255 255 255 / 15%) 25%,
    transparent 25%,
    transparent 50%,
    rgb(255 255 255 / 15%) 50%,
    rgb(255 255 255 / 15%) 75%,
    transparent 75%,
    transparent
  );
  background-size: 30px 30px;
  animation: animate-stripes 0.6s linear infinite;
  animation-duration: 1.25s;
  animation-direction: reverse;
}
