.topBarLogo {
  position: fixed;
  top: 3px;
  right: 3px;
  z-index: 1021;
  height: 45px;

  @media print {
    display: none;
  }
}
