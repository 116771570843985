@import 'style/colors';
@import 'style/sizes';

.sidebar {
  z-index: 2;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  width: $left-sidebar-width;
  height: 100vh;
  padding: 0 $left-sidebar-nav-padding-x;
  overflow: hidden auto;
  background-color: $dark-blue;

  &::-webkit-scrollbar {
    display: none;
  }
}

.sidebarLogo {
  width: 50px;
  margin: $top-padding auto 50px auto;
}

.sidebarIcon {
  text-align: center;
}

.sidebarLabel {
  margin-top: $spacer-xxs;
  color: #fff;
  text-transform: uppercase;
}

.bottomWrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
}

.sidebarAvatar {
  width: $left-sidebar-avatar-size;
  height: $left-sidebar-avatar-size;
  margin: 10px auto;
}

.link {
  cursor: pointer;
}

.navlink {
  width: calc(#{$left-sidebar-width} - (#{$left-sidebar-nav-padding-x} * 2));
  padding: 7px 0;
  margin-bottom: 16px;
  border-radius: $btn-border-radius;

  &:hover {
    background-color: rgb(255 255 255 / 30%);
  }
}

.active {
  background-color: rgb(255 255 255 / 30%);
}
