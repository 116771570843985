@import 'style/sizes';
@import 'style/colors';

.input {
  width: 100%;
  height: fit-content;
  cursor: inherit;
  resize: none;
  outline: none;
  border: none;
}

.editable:hover {
  cursor: text;
  resize: auto;
  border: 1px solid $secondary-100;
}
