@import 'style/colors';

.container {
  position: relative;
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #eee;
}

.selectedTab {
  font-weight: bold;
  color: $primary !important;
}

.tab {
  position: relative;
  bottom: -1px;
  display: inline-block;
  padding: 10px 20px 5px 0;
  font-size: 12px;
  color: $dark;
  text-transform: uppercase;
  cursor: pointer;
  list-style: none;
  border-bottom: none;

  &:focus {
    outline: none;
  }
}

.tabList {
  padding: 0;
  margin: 0;
  border-bottom: none;
}

.text {
  margin-right: 5px;
  font-size: 12px;
  color: #000;
}

.input {
  font-size: 12px;
  outline: none;
  border: none;

  &:focus {
    border-bottom: 1px solid $primary;
  }
}

.now {
  font-size: 12px;
}

.small {
  font-size: 11px;
}
