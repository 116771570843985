.selectedTab {
  font-weight: bold;
  color: #fff !important;
}

.tab {
  position: relative;
  bottom: -1px;
  display: inline-block;
  padding: 10px 20px 5px 0;
  font-size: 12px;
  color: #ccc;
  text-transform: uppercase;
  cursor: pointer;
  list-style: none;
  border-bottom: none;

  &:focus {
    outline: none;
  }
}

.tabList {
  padding: 0;
  margin: 0;
  border-bottom: none;
}
