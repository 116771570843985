.iconList {
  padding: 0;
  margin: 0 0 10px;
}

.iconListItem {
  position: relative;
  display: inline-block;
  padding: 10px 10px 10px 0;
  font-size: 9px;
  color: #d9d9d9;
  text-transform: uppercase;
  cursor: pointer;
  list-style: none;
  border-bottom: none;
}
